<template>
  <div>
    <el-row>
      <el-col style="padding-left: 10px">
        <el-button type="primary" @click="refundPaymentAdd()">增加退款参数</el-button>
      </el-col>
    <el-col style="margin-top: 20px;padding-left: 10px">
      <el-table
          :data="data"
          border
          v-loading="loading"
          element-loading-text="拼命加载中"
          max-height="650"
          :default-sort = "{prop: 'createDate', order: 'descending'}"
          style="width: 99%">
        <el-table-column prop="createDate" label="创建时间" align="center" fixed></el-table-column>
        <el-table-column prop="settlementName" label="退款方式名称" align="center" fixed></el-table-column>
        <el-table-column prop="accountName" align="center" label="账户名"></el-table-column>
        <el-table-column prop="accountNumber" align="center" label="账户号"></el-table-column>
        <el-table-column align="center" label="是否激活">
          <template slot-scope="scope">
          <el-switch v-model="scope.row.isValid"
                     active-color="#13ce66"
                     inactive-color="#ff4949"
                     @change="valueChange(scope.row)"
                     :active-value="1"
                     :inactive-value="0"
          >
          </el-switch>
          </template>
        </el-table-column>
        <el-table-column  align="center" label="操作">
          <template slot-scope="scope">
            <el-button-group>
              <el-button type="primary" size="mini" @click="refundPaymentEdit(scope.row)">修改</el-button>
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>
    </el-col>
    <el-col style="margin-top: 5px">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-size="100"
          layout="prev, pager, next,total, jumper, sizes"
          :total="total">
      </el-pagination>
    </el-col>
    </el-row>
    <refund-payment-comm ref="comm" @editOnSubmit="editOnSubmit"></refund-payment-comm>
  </div>
</template>

<script>
import refundPaymentComm from "./comm/refund-payment-comm"
export default {
  name: "refund-payment-list",
  created() {
    this.pageInit();
  },
  components:{
    refundPaymentComm
  },
  data() {
    return {
      data:[],
      total: 0,
      page: 1,
      limit: 100,
      loading: true,
    }
  },
  //页面初始化
  methods: {
    pageInit: function () {
      this.queryRfundPaymentList()
    },
    //查询退款账户参数列表
    queryRfundPaymentList:  async function () {
      let result = await this.$axios({
        method: "get",
        url: "/refundPayment/queryRefundPaymentList",
        params: {
          tenantCrop: localStorage.getItem("tenantCrop"),
          limit: this.limit,
          page: this.page,
        }
      })
      this.loading = false
      this.total = result.data.data.total
      this.data = result.data.data.list
    },
    //修改添加提交
    editOnSubmit: function (row){
      let alter = row.id===''?"添加":"修改"
      let msg = `<p><span>退款方式：</span><span style="color:darkred;">${row.settlementName}</span></p>`
              + `<p><span>账户管理人：</span><span style="color:darkred;">${row.accountName}</span></p>`
              + `<p><span>账户：</span><span style="color:darkred;">${row.accountNumber}</span><span</p>`
              + `<p style="color:darkred;">确认要${alter}吗？</p>`
      this.$confirm(msg, '信息确认', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true,
        dangerouslyUseHTMLString:true
      }).then(async () => {
        let url = row.id === ''?"/refundPayment/addRefundPayment":"/refundPayment/updateRefundPayment"
        let result = await this.$axios({
          method: row.id === ''?"put":"post",
          url: url,
          params: row
        })
        if (result.data.code === 200) {
          this.$message({
            message: result.data.msg,
            type: 'success'
          });
          this.pageInit()
          this.$refs.comm.visible = false
        } else {
          this.$message({
            message: result.data.msg,
            type: 'warning'
          });
        }
      }).catch(() => {
      });
    },
    //打开添加页面
    refundPaymentAdd:function (){
      this.$refs.comm.params.tenantCrop = localStorage.getItem("tenantCrop")
      this.$refs.comm.title = "添加"
      this.$refs.comm.visible = true
    },
    //打开修改页面
    refundPaymentEdit: function (row) {
      let {id,settlementName,accountName,accountNumber,tenantCrop} = row
      this.$refs.comm.params = {id,settlementName,accountName,accountNumber,tenantCrop}
      this.$refs.comm.title = "修改"
      this.$refs.comm.visible = true
    },
    //激活按钮改变时的函数
    valueChange:async function (row){
      let result = await this.$axios({
        method: "post",
        url: "/refundPayment/updateRefundPayment",
        params: row
      })
      if (result.data.code === 200) {
        this.$message({
          message: result.data.msg,
          type: 'success'
        });
        this.pageInit()
        this.$refs.comm.visible = false
      } else {
        this.$message({
          message: result.data.msg,
          type: 'warning'
        });
      }
    },
    //分页控制
    handleSizeChange(val) {
      this.limit = val
      this.queryRfundPaymentList();
    },
    //分页控制
    handleCurrentChange(val) {
      this.page = val
      this.queryRfundPaymentList()
    },
  }
}
</script>

<style scoped>
 /deep/ .el-input-group__prepend{
  width: 74px;
}
</style>