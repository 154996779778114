<template>
  <div>
    <el-dialog
        :title="title+'退款参数界面'"
        width="30%"
        :visible.sync="visible"
        :modal-append-to-body="false"
        @close="close"
        center
    >
      <el-alert :title="title+'退款方式参数'" type="warning" show-icon :closable="false" class="bottom-controller" effect="dark"></el-alert>
      <el-alert title="该数据仅在退单退款时使用" type="warning" show-icon :closable="false" class="bottom-controller" effect="dark"></el-alert>
      <el-tooltip class="item" effect="dark" content="请输入   如:支付宝,XX银行,微信" placement="top" :disabled="params.settlementName!=''">
        <el-input placeholder="退款方式名称" v-model="params.settlementName" class="bottom-controller">
          <template slot="prepend">退款方式:</template>
        </el-input>
      </el-tooltip>
      <el-tooltip class="item" effect="dark" content="请输入   如:王XX,李XX" placement="top" :disabled="params.accountName!=''">
        <el-input placeholder="账户管理人" v-model="params.accountName" class="bottom-controller">
          <template slot="prepend" >账户管理人:</template>
        </el-input>
      </el-tooltip>
      <el-tooltip class="item" effect="dark" content="请输入   如:卡号,支付宝账号等" placement="top" :disabled="params.accountNumber!=''">
        <el-input placeholder="账号" v-model="params.accountNumber" class="bottom-controller">
          <template slot="prepend">账号:</template>
        </el-input>
      </el-tooltip>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click='onSubmit'>提 交</el-button>
        <el-button @click="visible=false">取 消</el-button>
    </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "refund-payment-comm",
  data() {
    return {
      visible: false,
      title:"",
      params: {
        id: "",
        settlementName: "",
        accountName: "",
        accountNumber: "",
        tenantCrop: "",
      },
    }
  },
  methods: {
    //退款账户参数编辑提交
    onSubmit: function () {
      let {settlementName,accountName,accountNumber} =  this.params
      let obj = {settlementName,accountName,accountNumber}
      if(Object.values(obj).every(s=>s!='')){
        this.$emit("editOnSubmit", this.params)
      }else {
        this.$message({
          message: '请将输入信息填写完整!',
          type: 'warning'
        });
        return
      }
    },
    //关闭时清空data数据
    close: function () {
      this.params.id = "",
      this.params.settlementName = "",
      this.params.accountName = "",
      this.params.accountNumber = "",
      this.params.tenantCrop = ""
    }
  }
}
</script>

<style scoped>
  .bottom-controller{
    margin-bottom: 10px;
  }
</style>